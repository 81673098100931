import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import News from './News'
import { ReactComponent as DownloadIcon } from '../../../images/Download.svg'
import CalendarIcon from '../../../images/calendar.svg'
import LocationIcon from '../../../images/location.svg'
import TraumaIcon from '../../../images/trauma.svg'
import HospitalIcon from '../../../images/hospital.svg'
import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'

export const languageToTranslatedDescriptionLanguage = {
  'en-gb': 'en-gb',
  'it-it': 'it',
  it: 'it',
  'es-es': 'es',
  es: 'es',
  'pt-pt': 'en-gb',
  br: 'en-gb',
  de: 'en-gb',
}

const DetailedInfo = ({ injury }) => {
  const { t, i18n } = useTranslation()
  const [selectedVideo, setVideo] = useState(0)
  const { media } = injury
  const hasMedia = media
    && Array.isArray(media)
    && !!injury.media.find(({ mime_type }) => mime_type)

  const title = ({
    injuried_part, injury_type, side, trauma,
  }) => {
    const sideAndPart = () => {
      if (!side || side === 'both') return t(`injury-part-${injuried_part}`)
      return t(`injury-part-${injuried_part}-${side}`)
    }
    const injuryTrauma = trauma !== 'na' ? t(`injury-contact-${trauma}`) : null
    const typeAndTrauma = _.filter([t(`injury-type-${injury_type}`), _.capitalize(injuryTrauma)])
    return `${sideAndPart()} – ${_.join(typeAndTrauma, ', ')}`
  }

  const location = ({ where, game_context, stadium }) => {
    if (where === 'training') {
      return `${t('injury-match-where-training')}${stadium ? `, ${stadium.name}` : ''}`
    }
    if (where === 'match_warmup') {
      return `${t('injury-match-where-match_warmup')}${game_context ? `, ${game_context}` : ''}${stadium ? `, ${stadium.name}` : ''
        }`
    }
    if (where === 'match' && game_context == null && stadium == null) {
      return t('injury-match-where-during_match')
    }
    return `${game_context || ''}${game_context && stadium ? ', ' : ''}${(stadium && stadium.name) || ''
      }`
  }

  const dates = ({
    date,
    end_date,
    recovery_days,
    actual_date,
    actual_recovery_days,
    injury_type,
    injury_category,
  }) => {
    if (injury_category === 'doping') {
      if (end_date) {
        return `${t('other-event-date')} ${moment(date).format('DD.MM.YYYY')} - ${moment(end_date).format('DD.MM.YYYY')}`
      }

      return `${t('other-event-date')} ${moment(date).format('DD.MM.YYYY')}`
    }

    const injuryDate = `${injury_type !== 'illness-or-other' ? `${t('injury-injured')} ` : ''
      }${moment(actual_date || date).format('DD.MM.YYYY')}`
    if (!end_date) {
      return injuryDate
    }
    return `${injuryDate} | ${t('injury-returned')} ${moment(end_date).format('DD.MM.YYYY')} (${actual_recovery_days || recovery_days
      } ${t('calendar-days')})`
  }

  const clinic = ({ clinic, doctor }) => {
    const doctorName = doctor
      ? `${t('injury-doctor-title')} ${doctor.first_name} ${doctor.last_name}`
      : null
    const clinicName = clinic ? `${clinic.name}, ${clinic.city} (${clinic.country_code})` : null
    return _.join(_.filter([doctorName, clinicName]), ', ')
  }

  const video = () => {
    if (!hasMedia) return null
    return (
      <Fragment>
        <InjuryVideo
          width='100%'
          height='auto'
          url={`${media[selectedVideo].url}#t=0.1`}
          controls
        />
        {media.length > 1 && (
          <VideoOptions>
            {media.map((v, index) => (
              <VideoOption active={selectedVideo === index} onClick={() => setVideo(index)} />
            ))}
          </VideoOptions>
        )}
      </Fragment>
    )
  }
  const currentLang = i18n.languages[0].toLowerCase()
  const targetLang = currentLang === 'pt-pt' || currentLang === 'fr-fr' ? 'en-gb' : currentLang

  return (
    <Wrapper>
      {video()}
      <InjuryTitle>
        <span>
          {injury.translated_description
            ? injury.translated_description[languageToTranslatedDescriptionLanguage[targetLang]]
            : injury.description}
        </span>
        {hasMedia && (
          <Download
            href={`${media[selectedVideo].url}?response-content-type=application/force-download`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <DownloadIcon />
          </Download>
        )}
      </InjuryTitle>

      {injury.description !== 'Covid-19' && injury.injury_category !== 'doping' && (
        <InjuryInfo icon={TraumaIcon}>{title(injury)}</InjuryInfo>
      )}
      <InjuryInfo icon={CalendarIcon}>{dates(injury)}</InjuryInfo>
      {(!_.includes(['other', 'none'], injury.where) || injury.game_context || injury.stadium) && (
        <InjuryInfo icon={LocationIcon}>{location(injury)}</InjuryInfo>
      )}
      {(injury.doctor || injury.clinic) && (
        <InjuryInfo icon={HospitalIcon}>{clinic(injury)}</InjuryInfo>
      )}

      {injury.news.length > 0 && <News injury={injury} />}
    </Wrapper>
  )
}

export default DetailedInfo

const Wrapper = styled.div`
  max-width: 650px;
`
const InjuryTitle = styled.p`
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.3;
  span {
    flex: 1 1 auto;
  }
  @media (max-width: 640px) {
    font-size: 18px;
  }
`
const InjuryVideo = styled(ReactPlayer)`
  & + ${InjuryTitle} {
    margin-top: 15px;
  }
`
const VideoOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 0;
`
const VideoOption = styled.div`
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  background-color: #e6e6e6;
  box-shadow: inset 0 0 0 1px #ccc;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s;
  ${props => props.active
    && css`
      background-color: ${cssvar('primaryColor')};
      box-shadow: none;
      pointer-events: none;
    `}
`
const Download = styled.a`
  flex: 0 0 auto;
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 15px;
  margin-top: 4px;
  svg {
    width: 100%;
    path {
      fill: ${cssvar('mainColor')};
      transition: 0.3s;
    }
    &:hover path {
      fill: ${cssvar('primaryColor')};
    }
  }
  @media (max-width: 640px) {
    width: 16px;
    height: 16px;
  }
`
const InjuryInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 470;
  color: ${cssvar('secondaryTextColor')};
  &:before {
    content: '';
    flex: 0 0 auto;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-image: url(${({ icon }) => icon});
    background-size: cover;
  }
  @media (max-width: 640px) {
    font-size: 14px;
    &:before {
      width: 20px;
      height: 20px;
    }
  }
`
