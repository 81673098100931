import React, { useState, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { getUserInfoSelector } from '../../redux/selectors/user'
import { readAllEnquiries, readEnquiry } from '../../api/enquiries'
import Dropdown, { DropdownItem } from './Dropdown'
import ReactSVG from 'react-svg'
import NotificationIcon from '../../images/notification.svg'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'
import Dialog from '../Dialog'
import CloseIcon from '../../images/close.svg'

const Notifications = () => {
  const { t, i18n } = useTranslation()
  const { notifications: allNotifications } = useSelector(getUserInfoSelector)
  const [localRead, setLocalRead] = useState([])
  const [alertMsg, setAlertMsg] = useState(null)
  const [modalActiveNotification, setModalActiveNotification] = useState(null)

  const notificationReadAtKey = id => `notification-${id}-readAt`

  const notifications = allNotifications
    ? allNotifications
      .map((n) => {
        const local_read_at = localStorage.getItem(notificationReadAtKey(n.id))
        return {
          ...n,
          read_at: local_read_at ? parseInt(local_read_at, 10) : null,
        }
      })
    : []

  const hasNotifications = notifications && notifications.length > 0

  const hasNewNotifications = hasNotifications
    && _.find(notifications, n => n.read_at === null && !_.includes(localRead, n.tag))

  const stickyNotification = notifications
    ? notifications.filter(not => not.type === 'sticky')[0]
    : null

  const modalNotification = notifications ? notifications.filter(not => not.type === 'modal')[0] : null

  const trimmedI18n = useMemo(() => {
    const language = i18n.language ? i18n.language : i18n.options.fallbackLng[0]
    return language.slice(0, 2)
  },
    [i18n, i18n.language])

  const handleNotificationTap = (notification) => {
    const {
      id, tag, type, topic, read_at,
    } = notification
    if (topic === 'ticket') {
      readEnquiry(id, tag)
    }
    if (!read_at) {
      if (tag) {
        setLocalRead(_.union(localRead, [tag]))
      }
      const date = new Date()
      localStorage.setItem(notificationReadAtKey(id), date.getTime())
    }
    if (topic !== 'ticket') {
      setModalActiveNotification(notification)
    }
  }

  const closeStickyNotification = (notification) => {
    setAlertMsg()
    const date = new Date()
    localStorage.setItem(notificationReadAtKey(notification.id), date.getTime())
  }

  const readAll = () => {
    readAllEnquiries()
    setLocalRead(notifications.map(n => n.tag))

    // loop through notifications and mark them as read
    const date = new Date()
    notifications.forEach((n) => {
      localStorage.setItem(notificationReadAtKey(n.id), date.getTime())
    })
  }
  const stickyAttach = document.getElementById('STICKY-ALERT')


  // Show sticky if read more than one day ago
  useEffect(() => {
    if (!stickyNotification) return
    const readAt = stickyNotification.read_at

    const now = new Date().getTime()
    const oneDayAgo = now - 86400000

    if (readAt < oneDayAgo) {
      setAlertMsg((stickyNotification.message_i18n[trimmedI18n] || stickyNotification.message))
    }
  }, [stickyNotification])

  useEffect(() => {
    if (!modalNotification || !!modalActiveNotification) return
    const readAt = modalNotification.read_at

    if (!readAt) {
      setModalActiveNotification(modalNotification)
    }
  }, [modalNotification])

  const handleModalClose = () => {
    const date = new Date()
    localStorage.setItem(notificationReadAtKey(modalActiveNotification.id), date.getTime())
    setModalActiveNotification(null)
  }


  return (
    <>
      {stickyAttach && alertMsg && !modalActiveNotification && createPortal(<Alert>
        <Close className='closeBtn' onClick={() => closeStickyNotification(stickyNotification)} closeBtnRightDistance="10px" />
        {alertMsg}
      </Alert>, stickyAttach)}
      {modalActiveNotification && createPortal(
        <Dialog open={!!modalActiveNotification} onClose={() => handleModalClose()} closeBtnRightDistance='5px' title={modalActiveNotification.message_i18n[trimmedI18n] || modalActiveNotification.message} css={{ display: 'block' }}>
          {modalActiveNotification.image && (
            <img
              style={{
                maxWidth: '500px', maxHeight: '600px', display: 'block', marginBottom: '10px',
              }}
              src={modalActiveNotification.image}
              alt={modalActiveNotification.message} />
          )}
          {modalActiveNotification.body_i18n[trimmedI18n] || modalActiveNotification.body}
        </Dialog>,
        stickyAttach,
      )}
      <Dropdown
        rightAligned
        withScroller
        disabled={!hasNotifications}
        trigger={(
          <IconWrapper>
            <ReactSVG src={NotificationIcon} />
            {hasNewNotifications && <Badge />}
          </IconWrapper>
        )}
      >
        {hasNewNotifications && <ReadAll onClick={readAll}>{t('mark-all-as-read')}</ReadAll>}
        {hasNotifications
          && notifications.map(
            // id, message, message_i18n, tag, image, read_at, topic, type,
            notification => (
              <NotificationMessage
                key={notification.id}
                onClick={() => handleNotificationTap(notification)}
                visited={notification.read_at !== null || _.includes(localRead, notification.tag)}
              >
                <PlayerPhoto src={notification.image} />
                {notification.message_i18n[trimmedI18n] || notification.message}
              </NotificationMessage>
            ),
          )}
      </Dropdown>
    </>
  )
}

export default Notifications

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  font-size: 0;
  position: relative;
  svg {
    width: 100%;
    path {
      fill: ${cssvar('background')};
      transition: 0.3s;
    }
  }
  &:hover svg path {
    fill: ${cssvar('oldPrimaryColor')};
  }
  @media (max-width: 640px) {
    width: 20px;
    height: 20px;
  }
`
const Badge = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ff006a;
  box-shadow: 0 0 0 2px ${cssvar('mainColor')};
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -1px;
  @media (max-width: 640px) {
    width: 8px;
    height: 8px;
  }
`
const NotificationMessage = styled(DropdownItem)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 320px;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  white-space: normal;
  ${props => !props.visited
    && css`
      font-weight: 600;
      color: white;
    `};
  &:last-child {
    border: none;
  }
`
const PlayerPhoto = styled.img`
  flex: 0 0 auto;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.5);
`
const ReadAll = styled(DropdownItem)`
  padding-top: 0;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  cursor: pointer;
`

const Alert = styled.div`
  position: relative;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  color: #fff;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 0.7rem;
    padding: 8px 16px;
  };
`

const Close = styled.div`
  position: absolute;  
  width: 26px;
  height: 26px;
  background-image: url(${CloseIcon});
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  position: absolute;
  right: ${({ closeBtnRightDistance }) => closeBtnRightDistance};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: .7;
  transition: .3s;
  &:hover {
    background-color: rgba(255,255,255, .1);
    opacity: 1;
  }
`

// const Modal = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 999;
//   display: flex;
//   justify-content: center;
//   align-items: center;`
